import {
  useCallback,
  useEffect,
  useState,
  type ChangeEvent,
  type FunctionComponent,
  type MutableRefObject,
} from 'react';
import { mdiFullscreen, mdiSkipNext } from '@mdi/js';
import Icon from '@mdi/react';

import { DataId } from '@/utils/nitrous/constants';

import Duration from './Duration';
import PlayPauseButton from './PlayPauseButton';
import ProgressBar from './ProgressBar';
import VolumeSlider from './VolumeSlider';

type VideoControlsProps = {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  initialVolume: number; // float from 0 - 1
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  duration: string; // milliseconds
  isMuted: boolean;
  showControls: boolean;
  showNextButton: boolean;
  toggleMute: () => void;
  togglePlayVideo: () => void;
  toggleFullscreen: () => void;
  loadNextVideo: () => void;
};

const VideoControls: FunctionComponent<VideoControlsProps> = ({
  containerRef,
  duration,
  initialVolume,
  isMuted,
  loadNextVideo,
  showControls,
  showNextButton,
  toggleFullscreen,
  toggleMute,
  togglePlayVideo,
  videoRef,
}) => {
  const [currentTime, setCurrentTime] = useState('0');
  const [currentVolume, setCurrentVolume] = useState(initialVolume);

  const handleVolumeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newVol = Number(event.target.value) / 10;

      const video = videoRef.current;
      if (newVol === 0 && !isMuted) {
        toggleMute();
      } else if (video) {
        setCurrentVolume(newVol);
        video.volume = newVol;
        if (isMuted) {
          toggleMute();
        }
      }
    },
    [isMuted, toggleMute, videoRef],
  );

  const handlePlayheadSeek = useCallback(
    (newTime: number) => {
      const _videoRef = videoRef.current;
      if (_videoRef) {
        _videoRef.currentTime = newTime;
      }
    },
    [videoRef],
  );
  const onTimeUpdate = useCallback(() => {
    const timeInSec = videoRef.current?.currentTime || 0;
    const timeInMs = timeInSec * 1000;

    setCurrentTime(`${timeInMs}`);
  }, [videoRef]);

  useEffect(() => {
    const _videoRef = videoRef.current;
    _videoRef?.addEventListener('timeupdate', onTimeUpdate);

    return () => {
      _videoRef?.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [onTimeUpdate, videoRef]);

  return (
    <div className="absolute bottom-0 left-0 right-0 z-20">
      {showControls && (
        <div className="bg-gradient-to-b from-transparent to-neutral-1">
          <div className="w-full px-2">
            <ProgressBar
              containerRef={containerRef}
              currentTime={currentTime}
              handlePlayheadSeek={handlePlayheadSeek}
              totalTime={duration}
            />
          </div>
          <div className="flex gap-2 p-4">
            <PlayPauseButton
              isPlaying={!videoRef.current?.paused}
              onClick={() => {
                togglePlayVideo();
              }}
            />

            {showNextButton && (
              <button
                aria-label="Load Next Video"
                data-id={DataId.NextVideoButton}
                data-parent={DataId.VideoPlayer}
                onClick={loadNextVideo}
              >
                <Icon color="white" path={mdiSkipNext} size={1} />
              </button>
            )}

            <VolumeSlider
              currentVolume={currentVolume || 0}
              handleVolumeChange={handleVolumeChange}
              isMute={isMuted}
              toggleMute={toggleMute}
            />

            <Duration currentTime={currentTime} totalTime={duration || '0'} />

            <button
              aria-label="Toggle Full Screen Video"
              className="ml-auto"
              data-id={DataId.VideoToggleFullScreenButton}
              data-parent={DataId.VideoPlayer}
              onClick={toggleFullscreen}
            >
              <Icon color="white" path={mdiFullscreen} size={1} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoControls;
