import { type FunctionComponent } from 'react';
import { VideoPlaylistStrings as strings } from '@/translations';
import {
  Badge,
  Card,
  CardContent,
  CardMedia,
  CardTitle,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@motortrend/ids';
import cx from 'clsx';

import formatVideoDuration from '@/utils/formatVideoDuration';
import { DataId } from '@/utils/nitrous/constants';
import AkamaiImage, { type AkamaiImageProps } from '@/components/AkamaiImage';

import { type VideoPlaylistProps } from './VideoPlaylist.props';

const VideoPlaylist: FunctionComponent<VideoPlaylistProps> = ({
  currentVideoId,
  onVideoClick,
  playlist,
  priority = false,
}) => {
  if (!playlist?.length) {
    return null;
  }
  const template = {
    defaultSize: '66vw',
    sizeConfig: {
      lg: '20vw',
      sm: '66vw',
    },
  };

  return (
    <Carousel className="pt-2 sm:pt-4">
      <CarouselContent className="sm:gap-4" data-id={DataId.VideoPlaylist}>
        {playlist.map((item, i) => {
          const { duration, thumbnailUrl, videoId, videoName } = item;
          const isPlaying = !!currentVideoId && currentVideoId === videoId;

          return (
            <CarouselItem key={`playlist-slide-${videoId}`}>
              <Card
                as="button"
                className={cx({ 'cursor-pointer': !isPlaying })}
                data-id={DataId.VideoPlaylistCard}
                data-parent={DataId.VideoPlaylist}
                onClick={
                  onVideoClick && !isPlaying ? () => onVideoClick(i) : undefined
                }
                orientation="vertical"
              >
                <CardMedia
                  alt={videoName?.trim()}
                  as={AkamaiImage}
                  aspectRatio="16/9"
                  imageProps={
                    {
                      priority: priority ? i < 4 : false,
                      template,
                    } as AkamaiImageProps
                  }
                  src={thumbnailUrl!}
                >
                  {isPlaying && (
                    <div
                      className="absolute inset-0 flex select-none items-center justify-center bg-neutral-2 bg-opacity-75 text-center font-bold text-neutral-8"
                      data-id="now-playing"
                    >
                      {strings.NowPlaying}
                    </div>
                  )}
                  {!isPlaying && (
                    <div className="absolute bottom-2 right-2">
                      <Badge ignoreTheme>{formatVideoDuration(duration)}</Badge>
                    </div>
                  )}
                </CardMedia>
                <CardContent>
                  <CardTitle
                    title={videoName}
                    titleTypographyProps={{
                      as: priority ? 'h2' : 'h3',
                      // `button2` doesn't apply colors by default
                      // See: https://ids.motortrend.com/?path=/docs/components-typography--documentation#typography-styles
                      className: 'text-neutral-2 dark:text-neutral-7',
                      maxLines: 3,
                      variant: 'button2',
                    }}
                  />
                </CardContent>
              </Card>
            </CarouselItem>
          );
        })}
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
};

export default VideoPlaylist;
