import { type google } from '@/types/Ima';

const getImaAdsManager = (
  adsLoader: google.ima.AdsLoader,
  videoContainer: HTMLDivElement,
  video: HTMLVideoElement,
  eventListeners: Partial<
    Record<google.ima.AdEvent.Type, google.ima.AdEvent.Listener>
  >,
): Promise<google.ima.AdsManager | null> =>
  new Promise((resolve, reject) => {
    const handleAdsManagerLoaded = (e: google.ima.AdsManagerLoadedEvent) => {
      if (!video) {
        reject(null);
      }

      const settings = new window.google.ima.AdsRenderingSettings();
      settings.loadVideoTimeout = 30000;
      settings.useStyledLinearAds = true;
      settings.autoAlign = false;

      const adsManager = e.getAdsManager(video, settings);

      Object.entries(eventListeners).forEach(([type, listener]) => {
        adsManager.addEventListener(
          type as google.ima.AdEvent.Type,
          listener,
          false,
        );
      });

      const iasConfig = {
        anId: '928969',
        campId: '818x480',
      };
      window.googleImaVansAdapter.init(
        window.google,
        adsManager,
        video,
        iasConfig,
      );
      adsManager.init(
        videoContainer.clientWidth,
        videoContainer.clientHeight,
        window.google.ima.ViewMode.NORMAL,
      );
      resolve(adsManager);
    };

    adsLoader.addEventListener(
      window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      handleAdsManagerLoaded,
      false,
    );
  });

export default getImaAdsManager;
