'use client';

import {
  useCallback,
  useRef,
  useState,
  type FunctionComponent,
  type MouseEvent,
} from 'react';
import { LinearProgress, Tooltip } from '@motortrend/ids';

import formatVideoDuration from '@/utils/formatVideoDuration';
import { DataId } from '@/utils/nitrous/constants';

import { type ProgressBarProps } from './ProgressBar.props';

const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  containerRef,
  currentTime,
  handlePlayheadSeek,
  totalTime,
}) => {
  const [playheadContent, setPlayheadContent] = useState('');
  const [playheadPosition, setPlayheadPosition] = useState<number | null>(null);
  const progressBarRef = useRef<HTMLButtonElement>(null);
  const progress = (+currentTime / +totalTime) * 100;

  // Given a mouse event on the progress bar, returns float from 0-1 signifying percentage position
  const getPosition = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const mousePositionPx = e.nativeEvent.layerX;
    const progressBarWidth =
      progressBarRef.current?.getBoundingClientRect().width;

    if (!progressBarWidth) {
      return 0;
    }
    return mousePositionPx / progressBarWidth;
  }, []);

  const seek = (e: MouseEvent<HTMLButtonElement>) => {
    const percentage = getPosition(e);
    const newTimeInMs = +totalTime * percentage;
    const newTimeInSec = Math.round(newTimeInMs / 1000);
    handlePlayheadSeek(newTimeInSec);
  };

  const showPlayhead = (e: MouseEvent<HTMLButtonElement>) => {
    const percentage = getPosition(e);
    const playheadTime = +totalTime * percentage;
    const newTimeInMs = Math.round(playheadTime);
    const playheadTimestamp = formatVideoDuration(`${newTimeInMs}`);
    setPlayheadContent(playheadTimestamp);
    setPlayheadPosition(percentage * 100);
  };

  const hidePlayhead = () => {
    setPlayheadContent('');
    setPlayheadPosition(null);
  };

  return (
    <button
      aria-label="Video Progress Bar"
      className="relative h-2 w-full"
      data-id={DataId.VideoProgressBar}
      data-parent={DataId.VideoPlayer}
      onClick={seek}
      onMouseLeave={hidePlayhead}
      onMouseMove={showPlayhead}
      ref={progressBarRef}
    >
      <Tooltip
        container={containerRef?.current || undefined}
        open={!!playheadContent}
        title={playheadContent}
      >
        <div
          className="absolute h-full w-0"
          style={{ left: `${playheadPosition}%` }}
        />
      </Tooltip>
      <LinearProgress
        disableTransitions
        value={progress}
        variant="determinate"
      />
    </button>
  );
};

export default ProgressBar;
