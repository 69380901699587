'use client';

import { useCallback, useEffect, useMemo, type MutableRefObject } from 'react';
import { useTrackVideo as _useTrackVideo } from '@motortrend/nitrous-web-sdk';

import { type google } from '@/types/Ima';
import { type Video } from '@/types/Video';

import { getVideoPayload } from '@/utils/nitrous/payloads/getVideoPayload';

const useTrackVideo = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  adsManager: google.ima.AdsManager | null,
  video: Video,
) => {
  const videoPayload = useMemo(() => getVideoPayload(video), [video]);
  const {
    logAdEnd,
    logAdPause,
    logAdPlay,
    logAdSkip,
    logEnd,
    logLoadStart,
    logPause,
    logPlay,
    logTimeUpdate,
  } = _useTrackVideo(videoRef);

  const onAdPlay = useCallback(
    (e: google.ima.AdEvent) => {
      logAdPlay({
        ad_content_title: e.getAd()?.getTitle() || '',
        ad_creative_id: e.getAd()?.getCreativeId() || '',
        ad_duration: Math.round(e.getAd()?.getDuration() || 0),
        ad_id: e.getAd()?.getAdId() || '',
      });
    },
    [logAdPlay],
  );

  useEffect(() => {
    if (!adsManager) {
      return;
    }

    adsManager.addEventListener(
      window.google.ima.AdEvent.Type.STARTED,
      onAdPlay,
    );
    adsManager.addEventListener(
      window.google.ima.AdEvent.Type.RESUMED,
      onAdPlay,
    );
    adsManager.addEventListener(
      window.google.ima.AdEvent.Type.PAUSED,
      logAdPause,
    );
    adsManager.addEventListener(
      window.google.ima.AdEvent.Type.COMPLETE,
      logAdEnd,
    );
    adsManager.addEventListener(
      window.google.ima.AdEvent.Type.SKIPPED,
      logAdSkip,
    );

    return () => {
      if (!adsManager) {
        return;
      }

      adsManager.removeEventListener(
        window.google.ima.AdEvent.Type.STARTED,
        onAdPlay,
      );
      adsManager.removeEventListener(
        window.google.ima.AdEvent.Type.RESUMED,
        onAdPlay,
      );
      adsManager.removeEventListener(
        window.google.ima.AdEvent.Type.PAUSED,
        logAdPause,
      );
      adsManager.removeEventListener(
        window.google.ima.AdEvent.Type.COMPLETE,
        logAdEnd,
      );
      adsManager.removeEventListener(
        window.google.ima.AdEvent.Type.SKIPPED,
        logAdSkip,
      );
    };
  }, [adsManager, logAdEnd, logAdPause, logAdSkip, onAdPlay]);

  useEffect(() => {
    const _videoRef = videoRef.current;

    if (!_videoRef) {
      console.error(
        'cannot attach media event listeners because `videoRef` is undefined. This likely indicates a race condition between the ref being set and it being passed to this component',
      );
      return;
    }

    const _logLoadStart = () => {
      logLoadStart(videoPayload);
    };
    _videoRef.addEventListener('loadstart', _logLoadStart);
    _videoRef.addEventListener('timeupdate', logTimeUpdate);
    _videoRef.addEventListener('play', logPlay);
    _videoRef.addEventListener('pause', logPause);
    _videoRef.addEventListener('ended', logEnd);

    return () => {
      if (!_videoRef) {
        return;
      }

      _videoRef.removeEventListener('loadstart', _logLoadStart);
      _videoRef.removeEventListener('timeupdate', logTimeUpdate);
      _videoRef.removeEventListener('play', logPlay);
      _videoRef.removeEventListener('pause', logPause);
      _videoRef.removeEventListener('ended', logEnd);
    };
  }, [
    logEnd,
    logLoadStart,
    logPause,
    logPlay,
    logTimeUpdate,
    videoPayload,
    videoRef,
  ]);

  return null;
};

export default useTrackVideo;
