import { type buildVastTagsProps } from '@/utils/ads/video/buildVastTag';

type RequiredCustParams = Pick<
  buildVastTagsProps,
  | 'mtid'
  | 'utm_campaign'
  | 'utm_content'
  | 'utm_id'
  | 'utm_medium'
  | 'utm_source'
  | 'utm_term'
  | 'utm_type'
>;

/**
 * Appends targeting values to the `cust_params` query parameter which are mission
 * critical and expected to be attached to all video ad requests. Since vast tags
 * can be defined in the cms, where we have no control over the format of the tag,
 * we cannot rely on the string template/replace method that is used in `buildVastTag`
 *
 * @see https://support.google.com/admanager/answer/10678356?hl=en#cust_params
 *
 * @remarks
 * In general, query parameters in vast tags are not arbitrary. There is a list
 * of well known parameters that may be used, including `cust_params`. Unlike
 * other vast tag parameters, cust_params _does_ allow us to attach an arbitrary
 * payload to ad requests. This is similar to our ad targeting for regular ads,
 * which gets set to prev_scp (prev slot cust params)
 * @todo we should really consider building vast tags like we do regular ads:
 * use json and convert to url encoded format at the end
 */
const appendCustParams = (
  url: string,
  requiredCustParams: RequiredCustParams,
): string => {
  const {
    mtid,
    utm_campaign,
    utm_content,
    utm_id,
    utm_medium,
    utm_source,
    utm_term,
    utm_type,
  } = requiredCustParams;

  if (!url) {
    // Note: on the first few 1-2 runs of useVastTag, adConfig has not been
    // hydrated (via AdConfigSetup) so `url` is empty
    return url;
  }

  try {
    const finalUrl = new URL(url);

    const custParams = Object.fromEntries(
      new URLSearchParams(
        finalUrl.searchParams.get('cust_params') || '',
      ).entries(),
    );
    const finalCustParams = new URLSearchParams({
      ...custParams,
      ...(mtid && { mtid }),
      ...(utm_campaign && { utm_campaign }),
      ...(utm_content && { utm_content }),
      ...(utm_id && { utm_id }),
      ...(utm_medium && { utm_medium }),
      ...(utm_source && { utm_source }),
      ...(utm_term && { utm_term }),
      ...(utm_type && { utm_type }),
      nextweb: 'true',
    }).toString();

    finalUrl.searchParams.set('cust_params', finalCustParams);

    return finalUrl.toString();
  } catch (e) {
    console.error(e);
  }

  // Encountered error, return unmodified url
  return url;
};

export default appendCustParams;
