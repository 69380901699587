import {
  VideoSource,
  VideoType,
  type VideoPayload,
} from '@motortrend/nitrous-web-sdk';

import { type Video } from '@/types/Video';

export const getVideoPayload = (video: Video): VideoPayload => {
  // Note that video_duration is expected to be reported in seconds by the nitrous data plan
  const videoDurationSeconds = Math.round(Number(video.duration || '0') / 1000);

  return {
    videoCasted: false,
    videoDurationSeconds,
    videoId: video.videoId || '',
    videoPublicationDate: video.publishedDate || '',
    videoSource: VideoSource.webiny,
    videoTitle: video.videoName || '',
    videoType:
      videoDurationSeconds > 600 ? VideoType.long_form : VideoType.short_form,
    // TODO: api needs to be updated to allow querying tags, category, and all other
    // attributes in the Video type: https://github.com/motortrend/mtg-next-web/blob/cdaf57fccf647c1c5f00433d5b458c752a85d001/schema.graphql#L2273-L2389
    //
    // video_season_name: get(video, 'season', '0'),
    // video_episode_number: Number(get(video, 'episode', '0')),
    // video_id: get(video, 'id'),
    // video_duration: duration,
    // video_category: get(video, 'categories.0'),
    // video_tags: video
  };
};
