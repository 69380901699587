import { VastTemplateConstants } from '@/constants';

export default function pathToVastPage(path: string) {
  if (path === '/') {
    return VastTemplateConstants.VastHomepagePageName;
  }
  // Remove leading and trailing slashes
  const cleanedPath = path.replace(/^\/+|\/+$/g, '');

  // Replace inner slashes with dashes
  return cleanedPath.replace(/\//g, '-');
}
