import { createSelector } from 'reselect';

import { AdConfigStateKey as StateKey } from '@/store/constants';
import { type AdConfigSlice as State } from '@/store/typings';

export const adConfigSelector = (state: State) => state[StateKey];

export const adTargetingSelector = createSelector(
  adConfigSelector,
  (state) => state.adTargeting,
);

export const adTargetingTemplateSelector = createSelector(
  adTargetingSelector,
  (state) => state.template,
);
