import { type google } from '@/types/Ima';

import getImaAdsLoader from './getImaAdsLoader';
import getImaAdsManager from './getImaAdsManager';
import getImaAdsRequest from './getImaAdsRequest';

/**
 * Initializes and requests video ads from the ima sdk. Handles setting up the
 * {@link https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsLoader AdsLoader},
 * {@link https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsRequest AdsRequest}, and
 * {@link https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsManager AdsManager}
 * classes following best practices from the {@link https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side#7.-listen-for-adsloader-events ima sdk guide}
 *
 * @see {@link https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side#7.-listen-for-adsloader-events ima sdk guide}
 * @remarks while this function requests ads, it does not start them. To do so, invoke
 * `adsManager.start()`
 */
const loadVideoAds = async (
  adContainer: HTMLDivElement,
  videoContainer: HTMLDivElement,
  video: HTMLVideoElement,
  vastTag: string,
  onAdError: google.ima.AdErrorEvent.Listener,
  eventListeners: Partial<
    Record<google.ima.AdEvent.Type, google.ima.AdEvent.Listener>
  >,
) => {
  // TODO: according to ima docs, adsLoader should be reused and not destroyed
  // https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side#6.-initialize-the-adsloader-and-make-an-ads-request
  const adsLoader = getImaAdsLoader(adContainer, onAdError);
  const adsRequest = getImaAdsRequest(videoContainer, vastTag);
  adsLoader.requestAds(adsRequest);
  const adsManager = await getImaAdsManager(
    adsLoader,
    videoContainer,
    video,
    eventListeners,
  );

  return { adsLoader, adsManager, adsRequest };
};

export default loadVideoAds;
