import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

/**
 * Transforms the video duration property to a human readable time
 * @return {string} The time in m:ss or h:mm:ss format
 */
const formatVideoDuration = (duration: string | null | undefined): string => {
  const millisecondsInHour = 60000 * 60;
  const formatMinutes = 'm:ss';
  const formatHours = 'H:mm:ss';

  let format = formatMinutes;
  let durationInt = 0;

  // Convert duration to a number
  if (duration) {
    durationInt = +duration;
  }

  // Choose alternate format if time is greater than or equal to one hour
  if (durationInt >= millisecondsInHour) {
    format = formatHours;
  }

  return dayjs.duration(durationInt).format(format);
};

export default formatVideoDuration;
