import { createSelector } from 'reselect';

import { UserStateKey as StateKey } from '@/store/constants';
import { type UserSlice as State } from '@/store/typings';

export const userSelector = (state: State) => state[StateKey];

export const userMeSelector = (state: State) => {
  if ('me' in state[StateKey]) {
    return state[StateKey].me;
  }
};

export const userEntitlementsSelector = (state: State) => {
  if ('entitlements' in state[StateKey]) {
    return state[StateKey].entitlements;
  }
};

export const userProfilesSelector = (state: State) => {
  if ('profiles' in state[StateKey]) {
    return state[StateKey].profiles;
  }
};

export const userTokensSelector = (state: State) => {
  if ('tokens' in state[StateKey]) {
    return state[StateKey].tokens;
  }
};

export const userDetailsSelector = (state: State) => {
  if ('details' in state[StateKey]) {
    return state[StateKey].details;
  }
};

export const userSubscriptionsSelector = (state: State) => {
  if ('subscriptions' in state[StateKey]) {
    return state[StateKey].subscriptions;
  }
};

export const userPendingTermsSelector = (state: State) => {
  if ('pendingTerms' in state[StateKey]) {
    return state[StateKey].pendingTerms;
  }
};

export const userPostalAddressesTermsSelector = (state: State) => {
  if ('postalAddresses' in state[StateKey]) {
    return state[StateKey].postalAddresses;
  }
};

export const userRetentionOffersTermsSelector = (state: State) => {
  if ('retentionOffers' in state[StateKey]) {
    return state[StateKey].retentionOffers;
  }
};

export const userConsentsTermsSelector = (state: State) => {
  if ('consents' in state[StateKey]) {
    return state[StateKey].consents;
  }
};

export const userCustomAttributesTermsSelector = (state: State) => {
  if ('customAttributes' in state[StateKey]) {
    return state[StateKey].customAttributes;
  }
};

export const userMeAttributesSelector = createSelector(
  userMeSelector,
  (state) => state?.data?.attributes,
);

export const userAnonymousSelector = createSelector(
  userMeAttributesSelector,
  (state): boolean => state?.anonymous ?? true,
);

export const userCurrentLocationTerritorySelector = createSelector(
  userMeAttributesSelector,
  (state): string | undefined => state?.currentLocationTerritory,
);

export const userIdSelector = createSelector(
  userMeSelector,
  (state): string | undefined => state?.data?.id,
);

export const userUsernameSelector = createSelector(
  userMeAttributesSelector,
  (state): string | undefined => state?.username,
);

export const userHasActiveAcquiredCapabilitiesSelector = createSelector(
  userEntitlementsSelector,
  (state): boolean =>
    state?.data?.attributes?.hasActiveAcquiredCapabilities ?? false,
);
