import { createSelector } from 'reselect';

import { FastTvStateKey as StateKey } from '@/store/constants';
import { type FastTvSlice as State } from '@/store/typings';

export const fastTvSelector = (state: State) => state[StateKey];

export const fastTvActiveHlsStreamSelector = createSelector(
  fastTvSelector,
  (state) => state.activeHlsStream,
);

export const fastTvActiveChannelUuidSelector = createSelector(
  fastTvSelector,
  (state) => state.activeChannelUuid,
);
