import { type FunctionComponent } from 'react';
import { VideoControlsStrings as strings } from '@/translations';
import { mdiPause, mdiPlay } from '@mdi/js';
import Icon from '@mdi/react';

import { DataId } from '@/utils/nitrous/constants';

import { type PlayPauseButtonProps } from './PlayPauseButton.props';

const PlayPauseButton: FunctionComponent<PlayPauseButtonProps> = ({
  isPlaying,
  onClick,
}) => {
  const ariaLabel = isPlaying
    ? strings.PauseButtonLabel
    : strings.PlayButtonLabel;
  return (
    <button
      aria-label={ariaLabel}
      data-id={DataId.VideoPlayButton}
      data-parent={DataId.VideoPlayer}
      onClick={onClick}
    >
      <Icon color="white" path={isPlaying ? mdiPause : mdiPlay} size={1} />
    </button>
  );
};

export default PlayPauseButton;
