/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';

/**
 * Custom React Hook to monitor full-screen status in real-time.
 * It tracks whether any element is in full-screen mode and identifies the full-screen element.
 *
 * @returns {{
 *   isFullScreen: boolean;
 *   fullScreenElement: Element | null;
 *   enterFullScreen: (element: HTMLElement) => Promise<void>;
 *   exitFullScreen: () => Promise<void>;
 * }}
 */
const useFullScreenStatus = () => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [fullScreenElement, setFullScreenElement] = useState<Element | null>(
    null,
  );

  /**
   * Checks the current full-screen status and updates state accordingly.
   */
  const checkFullScreen = useCallback(() => {
    const fsElement =
      document.fullscreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).msFullscreenElement ||
      null;

    setFullScreenElement(fsElement);
    setIsFullScreen(fsElement !== null);
  }, []);

  /**
   * Requests full-screen mode for a given element.
   *
   * @param {HTMLElement} element - The DOM element to display in full-screen.
   * @returns {Promise<void>}
   */
  const enterFullScreen = useCallback(async (element: HTMLElement) => {
    if (!element) return;

    const requestFS =
      element.requestFullscreen ||
      (element as any).webkitRequestFullscreen ||
      (element as any).mozRequestFullScreen ||
      (element as any).msRequestFullscreen;

    if (requestFS) {
      try {
        await requestFS.call(element);
      } catch (error) {
        console.error('Error attempting to enable full-screen mode:', error);
      }
    }
  }, []);

  /**
   * Exits full-screen mode.
   *
   * @returns {Promise<void>}
   */
  const exitFullScreen = useCallback(async () => {
    const exitFS =
      document.exitFullscreen ||
      (document as any).webkitExitFullscreen ||
      (document as any).mozCancelFullScreen ||
      (document as any).msExitFullscreen;

    if (exitFS) {
      try {
        await exitFS.call(document);
      } catch (error) {
        console.error('Error attempting to disable full-screen mode:', error);
      }
    }
  }, []);

  useEffect(() => {
    const fullscreenChangeEvents = [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'MSFullscreenChange',
    ];

    // Attach event listeners for all relevant full-screen change events
    fullscreenChangeEvents.forEach((event) => {
      document.addEventListener(event, checkFullScreen);
    });

    // Initial check in case the component mounts while already in full-screen
    checkFullScreen();

    // Cleanup event listeners on unmount
    return () => {
      fullscreenChangeEvents.forEach((event) => {
        document.removeEventListener(event, checkFullScreen);
      });
    };
  }, [checkFullScreen]);

  return { isFullScreen, fullScreenElement, enterFullScreen, exitFullScreen };
};

export default useFullScreenStatus;
