'use client';

import { useEffect, useMemo, useRef, type FunctionComponent } from 'react';
import cx from 'clsx';

import _useVastTag from '@/hooks/useVastTag';
import _useVideoAds from '@/hooks/useVideoAds';

import VideoMuteButton from '../VideoControls/VideoMuteButton';
import { type ContainerVideoElement } from '../VideoPlayer/VideoPlayer.props';
import { type VideoAdsProps } from './VideoAds.props';

const VideoAds: FunctionComponent<VideoAdsProps> = ({
  containerRef,
  duration,
  handleMuteOnClick,
  isFullscreen,
  isMute,
  pageTargeting,
  setAdsError,
  setAdsManager,
  useVastTag = _useVastTag,
  useVideoAds = _useVideoAds,
  vastTemplate,
  videoId,
  videoRef,
}) => {
  const adContainerRef = useRef<ContainerVideoElement>(null);

  const vastTag = useVastTag({
    containerRef,
    duration,
    pageTargeting,
    vastTemplate,
    videoId,
  });

  const { adsError, adsManager, showAdContainer } = useVideoAds({
    adContainerRef,
    containerRef,
    vastTag,
    videoRef,
  });

  useEffect(() => {
    if (adsManager) {
      setAdsManager(adsManager);
    }
  }, [adsManager, setAdsManager]);

  useEffect(() => {
    if (adsError) {
      setAdsError(adsError);
    }
  }, [adsError, setAdsError]);

  const containerClasses = useMemo(() => {
    return cx('absolute inset-0 z-30', {
      'hidden': !showAdContainer,
      'w-full h-full': isFullscreen,
    });
  }, [isFullscreen, showAdContainer]);

  useEffect(() => {
    // Remove absolute positioning from injected ad div
    const removeAbsolutePositioning = () => {
      if (adContainerRef.current) {
        const adDiv = adContainerRef.current.querySelector('div');
        if (adDiv) {
          adDiv.style.position = 'unset';
        }
      }
    };

    // Check periodically for the injected div and remove absolute positioning
    const checkInterval = setInterval(removeAbsolutePositioning, 200);

    // Cleanup
    return () => {
      clearInterval(checkInterval);
    };
  });

  return (
    <div>
      <div className={containerClasses} ref={adContainerRef}></div>
      {showAdContainer && (
        <VideoMuteButton
          className="z-30"
          isMute={isMute}
          onClick={handleMuteOnClick}
        />
      )}
    </div>
  );
};

export default VideoAds;
