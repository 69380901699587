'use client';

import { useEffect, useState } from 'react';
import useStore from '@/store';
import { useBreakpoint } from '@motortrend/ids';

import { videoManagerSelector } from '@/store/selectors';
import { type VideoManagerEntry } from '@/store/typings';

const getVisiblePlayers = (videoPlayers: {
  [id: string]: VideoManagerEntry;
}) => {
  return Object.values(videoPlayers).filter((player) => player.isVisible);
};

export type useVideoManagerProps = {
  playVideo: () => void;
  pauseVideo: () => void;
  playerId: string;
  allowSticky: boolean;
  allowAutoplay: boolean;
  isVisible: boolean;
  isDocumentVisible: boolean;
};

const useVideoManager = ({
  allowAutoplay,
  allowSticky,
  isDocumentVisible,
  isVisible,
  pauseVideo,
  playVideo,
  playerId,
}: useVideoManagerProps) => {
  const videoPlayers = useStore(videoManagerSelector);
  const { deleteVideoManagerEntry, setVideoManagerData } = useStore();

  const isDesktop = useBreakpoint('md');

  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    setVideoManagerData({
      isVisible,
      playerId,
      ...(isVisible && { lastSeen: Date.now() }),
    });
  }, [deleteVideoManagerEntry, isVisible, playerId, setVideoManagerData]);

  // Remove video manager entry when player unmounts
  useEffect(() => {
    return () => {
      deleteVideoManagerEntry(playerId);
    };
  }, [deleteVideoManagerEntry, playerId]);

  // Handle sticky
  useEffect(() => {
    // Only allow sticky on non-mobile screens
    if (!isDesktop) {
      setSticky(false);
      return;
    }
    const visiblePlayers = getVisiblePlayers(videoPlayers);

    const player = videoPlayers[playerId];

    const shouldStick = Boolean(
      !visiblePlayers.length && allowSticky && player?.lastSeen,
    );
    setSticky(shouldStick);
  }, [isDesktop, videoPlayers, playerId, allowSticky]);

  // Handle autoplay
  useEffect(() => {
    const hasMultiplePlayers = Object.keys(videoPlayers).length > 1;
    const visiblePlayers = getVisiblePlayers(videoPlayers);

    const sortedVisiblePlayers = visiblePlayers.sort((playerA, playerB) => {
      return (playerA.lastSeen || 0) - (playerB.lastSeen || 0);
    });

    const isOtherPlayerVisible = visiblePlayers.some(
      (player) => player.playerId !== playerId,
    );

    const isOldestVisiblePlayer =
      sortedVisiblePlayers[0]?.playerId === playerId;

    const shouldPlay =
      isDocumentVisible &&
      allowAutoplay &&
      ((isOtherPlayerVisible && isOldestVisiblePlayer) ||
        ((isVisible || isSticky) && !isOtherPlayerVisible) ||
        (!hasMultiplePlayers && isVisible));

    const shouldPause =
      (isOtherPlayerVisible && !isOldestVisiblePlayer) ||
      (!isVisible && !isSticky) ||
      !isDocumentVisible;

    if (shouldPlay) {
      playVideo();
    } else if (shouldPause) {
      pauseVideo();
    }
  }, [
    videoPlayers,
    playerId,
    isSticky,
    allowAutoplay,
    isVisible,
    playVideo,
    pauseVideo,
    isDocumentVisible,
  ]);

  return {
    isSticky,
  };
};

export default useVideoManager;
