import { type FunctionComponent } from 'react';
import { VideoControlsStrings as strings } from '@/translations';
import { mdiVolumeHigh, mdiVolumeOff } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '@motortrend/ids';
import cx from 'clsx';

import { DataId } from '@/utils/nitrous/constants';

import { type VideoMuteButtonProps } from './VideoMuteButton.props';

const VideoMuteButton: FunctionComponent<VideoMuteButtonProps> = ({
  className = 'z-10',
  isMute = true,
  onClick,
}) => {
  const dataId = isMute ? DataId.VideoUnmuteButton : DataId.VideoMuteButton;

  return (
    <div className={cx('absolute left-3.5 top-3.5', className)}>
      <Button
        className="rounded-3xl text-sm opacity-50"
        colorScheme="neutral-3"
        data-id={dataId}
        data-parent={DataId.VideoPlayer}
        onClick={onClick}
        size="small"
        startIcon={
          <Icon path={isMute ? mdiVolumeOff : mdiVolumeHigh} size={1} />
        }
        typographyVariant="caption2"
      >
        {isMute ? strings.UnmuteButton : strings.MuteButton}
      </Button>
    </div>
  );
};

export default VideoMuteButton;
