const getImaAdsRequest = (videoContainer: HTMLElement, vastTag: string) => {
  const { clientHeight, clientWidth } = videoContainer;
  const adsRequest = new window.google.ima.AdsRequest();
  adsRequest.setAdWillAutoPlay(true);
  adsRequest.setAdWillPlayMuted(true);
  adsRequest.adTagUrl = vastTag;
  adsRequest.linearAdSlotWidth = clientWidth;
  adsRequest.linearAdSlotHeight = clientHeight;
  adsRequest.nonLinearAdSlotWidth = clientWidth;
  adsRequest.nonLinearAdSlotHeight = clientHeight;
  adsRequest.vastLoadTimeout = 8000;
  adsRequest.contentDuration = 10;

  return adsRequest;
};

export default getImaAdsRequest;
