/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { useCallback, useEffect, useState, type MutableRefObject } from 'react';

import { type google } from '@/types/Ima';

import loadVideoAds from '@/utils/ads/video/loadVideoAds';
import loadScript from '@/utils/onetrust/loadScript';

import useFullScreenStatus from './useFullScreenStatus';

type useVideoAdsProps = {
  // Element that the IMA SDK renders ads into
  adContainerRef: MutableRefObject<HTMLDivElement | null>;
  // Container element for the video player, used for sizing
  containerRef: MutableRefObject<HTMLDivElement | null>;
  // The actual <video> element, used to control the player during ads
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  vastTag: string;
};

const useVideoAds = ({
  adContainerRef,
  containerRef,
  vastTag,
  videoRef,
}: useVideoAdsProps) => {
  const [adsManager, setAdsManager] = useState<google.ima.AdsManager | null>(
    null,
  );
  const [adsLibraryLoaded, setAdsLibraryLoaded] = useState(false);
  const [adsError, setAdsError] = useState(false);

  const [showAdContainer, setShowAdContainer] = useState(false);
  const { isFullScreen, enterFullScreen, exitFullScreen } =
    useFullScreenStatus();

  const onAdError = useCallback(
    (error?: google.ima.AdErrorEvent) => {
      // TODO: log error?
      if (error) {
        console.error(error);
      }
      setShowAdContainer(false);
      setAdsError(true);
      videoRef?.current?.play();
    },
    [videoRef],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const switchFullScreen = useCallback(
    (element: any) => {
      if (isFullScreen) {
        exitFullScreen().then(() => {
          enterFullScreen(element);
        });
      }
    },
    [enterFullScreen, exitFullScreen, isFullScreen],
  );

  const onContentPauseRequested = useCallback(() => {
    // exit full-screen with vendor prefixes
    // switchFullScreen(adContainerRef.current as any);
    videoRef?.current?.pause();
    setShowAdContainer(true);
  }, [videoRef]);

  const onContentResumeRequested = useCallback(() => {
    // switchFullScreen(videoRef.current as any);
    videoRef?.current?.play();
    setShowAdContainer(false);
  }, [videoRef]);

  const onResize = useCallback(() => {
    if (!containerRef.current || !adsManager) {
      return;
    }
    const { clientHeight, clientWidth } = containerRef.current;
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (isFullScreen) {
      adsManager.resize(width, height, window.google.ima.ViewMode.FULLSCREEN);
    } else {
      adsManager.resize(
        clientWidth,
        clientHeight,
        window.google.ima.ViewMode.NORMAL,
      );
    }
  }, [adsManager, containerRef, isFullScreen]);

  useEffect(() => {
    let _adsLoader: google.ima.AdsLoader | null = null;
    let _adsManager: google.ima.AdsManager | null = null;

    if (
      !adContainerRef.current ||
      !containerRef.current ||
      !videoRef.current ||
      !adsLibraryLoaded ||
      !vastTag
    ) {
      // Not ready to render ads yet
      return;
    }

    loadVideoAds(
      adContainerRef.current,
      containerRef.current,
      videoRef.current,
      vastTag,
      onAdError,
      {
        contentPauseRequested: onContentPauseRequested,
        contentResumeRequested: onContentResumeRequested,
      },
    )
      .then(({ adsLoader, adsManager }) => {
        _adsLoader = adsLoader;
        _adsManager = adsManager;
        setAdsManager(adsManager);
        adsManager?.start();
      })
      .catch(onAdError);

    return () => {
      _adsManager?.destroy();
      // TODO: according to ima docs, adsLoader should be reused and not destroyed.
      // We should call contentComplete here instead
      // https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/faq#8
      _adsLoader?.destroy();
    };
  }, [
    adContainerRef,
    adsLibraryLoaded,
    containerRef,
    onAdError,
    onContentPauseRequested,
    onContentResumeRequested,
    vastTag,
    videoRef,
  ]);

  useEffect(() => {
    const loadIma3 = loadScript(
      'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
    );
    const loadAdapter = loadScript(
      'https://static.adsafeprotected.com/vans-adapter-google-ima.js',
    );
    Promise.all([loadIma3, loadAdapter])
      .then(() => {
        setAdsLibraryLoaded(true);
      })
      .catch(() => {
        onAdError();
      });
  }, [onAdError]);

  // When video container changes size, update the adsManager
  useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef?.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, onResize]);

  return {
    adsError,
    adsManager,
    showAdContainer,
  };
};

export default useVideoAds;
