import { useState, type FunctionComponent } from 'react';
import { VideoControlsStrings as strings } from '@/translations';
import { mdiVolumeHigh, mdiVolumeMute } from '@mdi/js';
import Icon from '@mdi/react';

import { DataId } from '@/utils/nitrous/constants';

import { type VolumeSliderProps } from './VolumeSlider.props';

const VolumeSlider: FunctionComponent<VolumeSliderProps> = ({
  currentVolume,
  handleVolumeChange,
  isMute,
  toggleMute,
}) => {
  const [showSlider, setShowSlider] = useState(false);

  const buttonLabel = isMute
    ? strings.UnmuteButtonLabel
    : strings.MuteButtonLabel;
  const dataId = isMute
    ? DataId.VideoVolumeSliderUnmuteButton
    : DataId.VideoVolumeSliderMuteButton;

  return (
    <div
      className="flex transition"
      data-id="volume-controls"
      onMouseEnter={() => {
        setShowSlider(true);
      }}
      onMouseLeave={() => {
        setShowSlider(false);
      }}
    >
      <button
        aria-label={buttonLabel}
        data-id={dataId}
        data-parent={DataId.VideoPlayer}
        onClick={toggleMute}
      >
        <Icon
          color="white"
          path={isMute ? mdiVolumeMute : mdiVolumeHigh}
          size={1}
        />
      </button>
      {/* TODO: animate slideout. Tailwind doesn't have transition-width */}
      {showSlider && (
        <input
          aria-label={strings.VolumeSliderLabel}
          className="w-20 cursor-pointer bg-transparent accent-neutral-8"
          data-id="volume-slider"
          max="10"
          min="0"
          onChange={handleVolumeChange}
          type="range"
          value={isMute ? 0 : currentVolume * 10}
        />
      )}
    </div>
  );
};

export default VolumeSlider;
