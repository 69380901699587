import { useMemo, type FunctionComponent } from 'react';
import { Typography } from '@motortrend/ids';

import formatVideoDuration from '@/utils/formatVideoDuration';

import { type DurationProps } from './Duration.props';

const Duration: FunctionComponent<DurationProps> = ({
  currentTime,
  totalTime,
}) => {
  const currentTimeDisplay = useMemo(() => {
    return formatVideoDuration(currentTime);
  }, [currentTime]);

  const totalTimeDisplay = useMemo(() => {
    return formatVideoDuration(totalTime);
  }, [totalTime]);

  const durationDisplay = `${currentTimeDisplay} / ${totalTimeDisplay}`;
  return (
    <Typography className="text-neutral-8" variant="caption1">
      {durationDisplay}
    </Typography>
  );
};

export default Duration;
