import { createSelector } from 'reselect';

import { UserProfileStateKey as StateKey } from '@/store/constants';
import { type UserProfileSlice as State } from '@/store/typings';

export const userProfileSelector = (state: State) => state[StateKey].data;

export const userProfileStatusSelector = (state: State) =>
  state[StateKey].status;

export const userProfileAvatarUrlSelector = createSelector(
  userProfileSelector,
  (state) => state.avatar,
);

export const userProfileUserNameSelector = createSelector(
  userProfileSelector,
  (state) => state.userName,
);
