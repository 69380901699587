'use client';

import { useCallback, useRef, useState } from 'react';

import {
  type ContainerVideoElement,
  type FullscreenVideoElement,
} from '@/components/VideoPlayer/VideoPlayer.props';

export const useToggleFullscreen = () => {
  const containerRef = useRef<ContainerVideoElement | null>(null);
  const videoRef = useRef<FullscreenVideoElement | null>(null);
  const [isFullscreen, setIsFullScreen] = useState<boolean>(false);

  const toggleFullscreen = useCallback(() => {
    const containerElement = containerRef?.current;
    const videoElement = videoRef?.current;
    if (isFullscreen) {
      // fullscreenHandler will update the `isFullScreen` state
      // as the user can also exit via browser controls
      if (document) {
        document.exitFullscreen();
      }
    } else {
      setIsFullScreen(true);
      if (containerElement?.requestFullscreen) {
        containerElement?.requestFullscreen();
        // # for Safari (older versions)
      } else if (containerElement?.webkitRequestFullScreen) {
        containerElement?.webkitRequestFullScreen();
        // # for Safari (newer versions)
      } else if (containerElement?.webkitEnterFullscreen) {
        containerElement?.webkitEnterFullscreen();
      } else if (containerElement?.msRequestFullscreen) {
        // # for Internet Explorer/Edge
        containerElement.msRequestFullscreen();
      } else if (containerElement?.mozRequestFullScreen) {
        // # for Mozilla
        containerElement.mozRequestFullScreen();
        // # for Safari iPhone (where only the Video tag itself can be fullscreen)
        // TODO: Make visible Custom controls in Safari iOS
        // Currently Safari iPhone will show default controls in fullscreen mode due to some restrictions.
      } else if (videoElement?.webkitEnterFullscreen) {
        videoElement?.webkitEnterFullscreen();
      }
    }
  }, [isFullscreen]);

  return {
    containerRef,
    isFullscreen,
    setIsFullScreen,
    toggleFullscreen,
    videoRef,
  };
};
