import appendCustParams from '@/utils/ads/video/appendCustParams';

export type buildVastTagsProps = {
  brand: string;
  duration: string;
  height: number;
  make?: string;
  model?: string;
  mtid: string | null | undefined;
  page: string;
  ppc?: string | null;
  sm_id?: string | null;
  utm_campaign: string | null | undefined;
  utm_content: string | null | undefined;
  utm_id: string | null | undefined;
  utm_medium: string | null | undefined;
  utm_source: string | null | undefined;
  utm_term: string | null | undefined;
  utm_type: string | null | undefined;
  vastTemplate: string;
  videoId: string;
  width: number;
  sect: string;
};

// A vast template is a url that we need to interpolate data into for ads requests
// Default vast teplates for each template are hardcoded in the AdConfig utilities
// Arbitrary custom vast templates can be attached to a video/playlist in curator/CMS
export default function buildVastTag({
  brand,
  duration,
  height,
  make,
  model,
  mtid,
  page,
  ppc,
  sect,
  sm_id,
  utm_campaign,
  utm_content,
  utm_id,
  utm_medium,
  utm_source,
  utm_term,
  utm_type,
  vastTemplate,
  videoId,
  width,
}: buildVastTagsProps): string {
  let url = vastTemplate;

  const timestamp = Date.now();

  // TODO: work with adops to standardize these?
  url = url.replace('[DATE]', `${timestamp}`);
  url = url.replace('[timestamp]', `${timestamp}`);
  url = url.replace('[WIDTH]', `${width}`);
  url = url.replace('[HEIGHT]', `${height}`);
  url = url.replace('[ID]', videoId);
  url = url.replace('[mediaProxy.entry.id]', videoId);
  url = url.replace('[DURATION]', `${duration}`);
  url = url.replace('[PAGE]', page);
  url = url.replace('[SM_ID]', sm_id || '');
  url = url.replace('[PPC]', ppc || '');
  url = url.replace('[SECT]', sect);
  url = url.replace('[brand]', brand);

  if (make) {
    url += `&make=${make.toLowerCase()}`;
  }
  if (model) {
    url += `&model=${model.toLowerCase()}`;
  }

  return appendCustParams(url, {
    mtid,
    utm_campaign,
    utm_content,
    utm_id,
    utm_medium,
    utm_source,
    utm_term,
    utm_type,
  });
}
