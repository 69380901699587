import { nitrous } from '@motortrend/nitrous-web-sdk';

import { google } from '@/types/Ima';

const getImaAdsLoader = (
  adContainer: HTMLElement,
  onAdError: google.ima.AdErrorEvent.Listener,
) => {
  const adDisplayContainer = new window.google.ima.AdDisplayContainer(
    adContainer,
  );

  adDisplayContainer.initialize();

  const adsLoader: google.ima.AdsLoader = new window.google.ima.AdsLoader(
    adDisplayContainer,
  );

  const adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
  adsRenderingSettings.enablePreloading = true;

  adsLoader
    .getSettings()
    .setVpaidMode(window.google.ima.ImaSdkSettings.VpaidMode.DISABLED);
  adsLoader.getSettings().setDisableFlashAds(true);
  const mtid = nitrous.user.getMtid();
  if (mtid) {
    adsLoader.getSettings().setPpid(mtid);
  }
  adsLoader.addEventListener(
    window.google.ima.AdErrorEvent.Type.AD_ERROR,
    onAdError,
    false,
  );

  return adsLoader;
};

export default getImaAdsLoader;
