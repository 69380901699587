'use client';

import { useCallback, useEffect, useState } from 'react';

const useDocumentVisible = () => {
  const [isDocumentVisible, setIsDocumentVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsDocumentVisible(document.visibilityState === 'visible');
  }, [setIsDocumentVisible]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return isDocumentVisible;
};

export default useDocumentVisible;
