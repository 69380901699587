'use client';

import { useMemo, type MutableRefObject } from 'react';
import { usePathname as _usePathname } from 'next/navigation';
import { nitrous } from '@motortrend/nitrous-web-sdk';

import { AdConfig } from '@/types/AdConfig';

import buildVastTag from '@/utils/ads/video/buildVastTag';
import pathToVastPage from '@/utils/ads/video/pathToVastPage';
import useTargetingQueryParams from '@/hooks/useTargetingQueryParams';

type useVastTagProps = {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  vastTemplate?: string;
  duration: string;
  videoId: string;
  pageTargeting: AdConfig;
  usePathname?: () => string;
};

const useVastTag = ({
  containerRef,
  duration,
  usePathname = _usePathname,
  vastTemplate: overrideVastTemplate,
  videoId,
  pageTargeting,
}: useVastTagProps) => {
  const { adTargeting, defaultVastTemplate } = pageTargeting;

  const vastTemplate = useMemo(() => {
    return overrideVastTemplate || defaultVastTemplate;
  }, [defaultVastTemplate, overrideVastTemplate]);

  const pathName = usePathname();
  const {
    ppc,
    sm_id,
    utm_campaign,
    utm_content,
    utm_id,
    utm_medium,
    utm_source,
    utm_term,
    utm_type,
  } = useTargetingQueryParams();
  const page = useMemo(() => pathToVastPage(pathName || ''), [pathName]);

  // TODO: make/model

  const vastTag = useMemo(
    () =>
      buildVastTag({
        brand: adTargeting?.brand || '',
        duration,
        height: containerRef.current?.clientHeight || 0,
        mtid: nitrous.user.getMtid(),
        page,
        ppc,
        sect: adTargeting.sect || '',
        sm_id,
        utm_campaign,
        utm_content,
        utm_id,
        utm_medium,
        utm_source,
        utm_term,
        utm_type,
        vastTemplate,
        videoId,
        width: containerRef.current?.clientWidth || 0,
      }),
    [
      adTargeting,
      duration,
      containerRef,
      page,
      ppc,
      sm_id,
      utm_campaign,
      utm_content,
      utm_id,
      utm_medium,
      utm_source,
      utm_term,
      utm_type,
      vastTemplate,
      videoId,
    ],
  );

  return vastTag;
};

export default useVastTag;
